@import '../../../base/assets/scss/_base/config';

.m-popular-videos {
  background: $marshmallow;
  border-top: 2px solid $dory;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-base);
  height: 100%;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 0;
  transition: padding-top .2s cubic-bezier(.23, 1, .32, 1);
  width: 300px;
  z-index: 120;

  @media screen and (max-width: 754px) {
    width: 100%;
  }

  .header-title {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 18px;
    margin-top: 0;
    padding: 20px 20px 0;
    text-align: left;
  }

  .list-post {
    list-style: none;
    margin: 0 0 5px;
    margin: 0 0 0 -10px;
    width: 100%;
    width: auto;

    .list-post-item {
      border-bottom: 2px solid $white;
      display: flex;
      margin: 0;
      margin: 0 0 5px;
      padding: 10px;
      text-align: left;
      text-overflow: ellipsis;
      width: 100%;

      .list-post-image {
        margin-right: 12px;
        overflow: hidden;
        width: 47%;
        position: relative;

        .list-post-link {
          height: inherit;
          line-height: 0;
          width: 100%;

          .list-item-img {
            background-color: $dirty_seagull;
            height: 100px;
            width: 100%;
          }
        }

        .play-btn-img {
          position: absolute;
          background: rgba(0, 0, 0, .5);
          background-color: #000;
          border-radius: 50%;
          bottom: 5px;
          box-sizing: border-box;
          display: flex;
          fill: #fff;
          font-size: 18px;
          font-weight: 400;
          height: 30px;
          left: 0;
          line-height: 28px;
          margin-left: 5px;
          margin-top: -35px;
          padding: 5px;
          text-align: center;
          transform: rotate(270deg);
          width: 30px;
          z-index: 2;
        }
      }

      .list-post-description {
        width: 47%;

        .list-post-title {
          display: flex;
          font-size: 16px;
          font-weight: 300;
          height: 90px;
          line-height: 22px;
          margin-top: -5px;
          overflow: hidden;

          .list-post-link {
            background: transparent;
            border-bottom: 1px solid transparent;
            color: #000;
            cursor: pointer;
            display: inline-flex;
            font: inherit;
            height: 100%;
            -webkit-line-clamp: 4;
            margin-top: 4px;
            overflow: hidden;
            padding: 0;
            text-decoration: none;
            text-overflow: ellipsis;
            transition: all .2s cubic-bezier(0.23, 1, 0.32, 1);
          }
        }
      }
    }

    .list-post-item:hover {
      .list-post-description .list-post-link {
        color: $dory;
      }

      .play-btn-img {
        background-color: $dory;
      }
    }

    .list-post-item:last-child { margin-bottom: 0; }
  }
}
